// src/pages/SecondYear.js

import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import BackButton from "../../Components/BackButton";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f0f0f0;
`;

const Title = styled.h1`
    font-size: 2.5em;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
`;

const Button = styled.button`
    width: 300px;
    padding: 15px;
    margin: 10px;
    font-size: 1.2em;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

function SecondYear() {
    const navigate = useNavigate();

    return (
        <Container>
            <BackButton to="/" />
            <Title>Druhý ročník</Title>
            <Button
                onClick={() =>
                    navigate("/druhy-rocnik/spolocenstvo-cirkvi-a-liturgia")
                }
            >
                10. SPOLOČENSTVO CIRKVI A LITURGIA
            </Button>
        </Container>
    );
}

export default SecondYear;
