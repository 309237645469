const Texts = {
    "spolocenstvo-cirkvi-a-liturgia": {
        title: "10. SPOLOČENSTVO CIRKVI A LITURGIA",
        start: "28.10.2024",
        content: [
            {
                week: "1. týždeň",
                daysContent: [
                    {
                        day: "intro",
                        title: "Úvod do týždňa",
                        text: "Boh stvoril človeka na svoj obraz. On sám je jednotou troch osôb, a preto človeka uspôsobil na prežívanie spoločenstva s inými ľuďmi. Ak sa cítime sami a opustení, je to práve tá chvíľa, kedy môžeme vykročiť k ľuďom. Boh do nás vložil túžbu žiť v spoločenstve s inými a On sám nás pomocou pôsobenia Ducha Svätého učí, ako byť súčasťou jednoty tela v jej rozdielnosti (porov. 1 Kor 12,12-13). Dajme sa tento týždeň viesť Duchom Svätým pri poznávaní dôležitosti spoločenstva v Božom pláne spásy.",
                        scriptures: [],
                    },

                    {
                        day: 1,
                        title: "Pondelok",
                        text: "Otec je dokonale jedno so Synom a Syn je dokonale jedno s Otcom. Dokonalá láska. Aj my máme podiel na tomto spoločenstve. Sám Ježiš sa prihovára za nás, aby sme mohli prežívať túto jednotu. Uver tomuto prisľúbeniu a nechaj sa pretvárať Božou láskou.",
                        scriptures: [
                            {
                                reference: "Jn 17, 20-23",
                                text: "Ale neprosím iba za nich, ale aj za tých, čo skrze ich slovo uveria vo mňa, aby všetci boli jedno, ako ty, Otče, vo mne a ja v tebe, aby aj oni boli v nás jedno, aby svet uveril, že si ma ty poslal. A slávu, ktorú si dal mne, dal som ja im, aby boli jedno, ako sme my jedno - ja v nich a ty vo mne, aby boli dokonale jedno, aby svet poznal, že si ma ty poslal a že ich miluješ, ako miluješ mňa.",
                            },
                        ],
                    },
                    {
                        day: 2,
                        title: "Utorok",
                        text: "„...vytrvalo sa zúčastňovali na modlitbách.“ Pros dnes za vernosť a vytrvalosť v nasledovaní Ježiša Krista a pri stretávaní sa s inými bratmi a sestrami, aby aj o tvojom spoločenstve mohli ľudia povedať: „Hľa, ako sa milujú!“",
                        scriptures: [
                            {
                                reference: "Sk 2, 42-47",
                                text: "Vytrvalo sa zúčastňovali na učení apoštolov, na spoločenstve, na lámaní chleba a na modlitbách. A každého sa zmocnila bázeň, lebo apoštoli robili mnoho zázrakov a znamení. Všetci, čo uverili, boli pospolu a všetko mali spoločné; predávali majetky a statky a rozdeľovali ich všetkým, ako kto potreboval. Každý deň svorne zotrvávali v chráme, lámali chlieb po domoch a prijímali pokrm s radosťou a v jednoduchosti srdca, velebili Boha a boli milí všetkému ľudu. A Pán každý deň rozmnožoval tých, čo mali byť spasení.",
                            },
                        ],
                    },
                    {
                        day: 3,
                        title: "Streda",
                        text: "Pavol tu napomína Korinťanov, že sa neschádzajú kvôli sláveniu Pánovej večere, ale aby jedli a pili. Vznikajú medzi nimi rozkoly. Veľakrát je to tak aj v našich spoločenstvách. Ak máš s niekým nejaký spor, skús ho tento týždeň odovzdať Bohu a s jeho pomocou ho riešiť.",
                        scriptures: [
                            {
                                reference: "1 Kor 11, 17-22",
                                text: "17 Keď vás už napomínam, nechválim vás, že sa neschádzate na lepšie, ale na horšie. 18 Predovšetkým počúvam, že sú medzi vami roztržky, keď sa schádzate v cirkvi. A sčasti tomu aj verím. 19 Lebo musia byť medzi vami aj rozkoly, aby sa ukázalo, kto z vás sa osvedčí. 20 Keď sa teda schádzate, nie je to požívanie Pánovej večere, 21 lebo každý si hneď vezme a zje svoju večeru a potom jeden je hladný a druhý opitý. 22 Nemáte vari domy, kde môžete jesť a piť? Alebo opovrhujete Božou cirkvou a chcete zahanbiť tých, čo nič nemajú? Čo vám mám povedať? Mám vás chváliť? Za toto vás nepochválim.",
                            },
                        ],
                    },
                    {
                        day: 4,
                        title: "Štvrtok",
                        text: "Všetko, čo konáme, má byť k oslave Ježiša Krista. Nech meradlom úspešnosti každej našej činnosti v spoločenstve je to, aké miesto mal v nej Boh.",
                        scriptures: [
                            {
                                reference: "Kol 3, 16-17",
                                text: "Kristovo slovo nech vo vás bohato prebýva. Vo všetkej múdrosti sa navzájom poúčajte a napomínajte a pod vplyvom milosti spievajte Bohu vo svojich srdciach žalmy, hymny a duchovné piesne. A všetko, čokoľvek hovoríte alebo konáte, všetko robte v mene Pána Ježiša a skrze neho vzdávajte vďaky Bohu Otcovi.",
                            },
                        ],
                    },
                    {
                        day: 5,
                        title: "Piatok",
                        text: "Ježiš ti dnes hovorí, čo všetko je potrebné robiť pre zachovanie jednoty. Sľubuje, že on sám bude prítomný v spoločenstve ľudí zídených v jeho mene. Vyžaduje však našu snahu neustále odpúšťať. Pros dnes Boha za milosť dokázať odpustiť svojmu bratovi.",
                        scriptures: [
                            {
                                reference: "Mt 18, 15-22",
                                text: '15 Keď sa tvoj brat prehreší proti tebe, choď a napomeň ho medzi štyrmi očami. Ak ťa počúvne, získal si svojho brata. 16 Ak ťa nepočúvne, priber si ešte jedného alebo dvoch, aby bola každá výpoveď potvrdená ústami dvoch alebo troch svedkov. 17 Keby ani ich nepočúvol, povedz to cirkvi. A keby ani cirkev nechcel poslúchnuť, nech ti je ako pohan a mýtnik. 18 Veru, hovorím vám: Čo zviažete na zemi, bude zviazané v nebi, a čo rozviažete na zemi, bude rozviazané v nebi. 19 A zasa vám hovorím: Ak budú dvaja z vás na zemi jednomyseľne prosiť o čokoľvek, dostanú to od môjho Otca, ktorý je na nebesiach. 20 Lebo kde sú dvaja alebo traja zhromaždení v mojom mene, tam som ja medzi nimi." 21 Vtedy k nemu pristúpil Peter a povedal mu: "Pane, koľko ráz mám odpustiť svojmu bratovi, keď sa proti mne prehreší? Azda sedem ráz?" 22 Ježiš mu odpovedal: "Hovorím ti: Nie sedem ráz, ale sedemdesiatsedem ráz',
                            },
                        ],
                    },
                    {
                        day: 6,
                        title: "Sobota",
                        text: "Mladík Eutychus sedel na okne a zaspal, pretože Pavol dlho hovoril. Spadol a zomrel. Aký/(á) som ja? Zúčastňujem sa na živote spoločenstva, alebo len pozorujem, „sedím na okne“, nie som ani dnu ani von? Ostanem dnu, alebo odídem a „zomriem“? Boh necháva rozhodnutie na teba.",
                        scriptures: [
                            {
                                reference: "Sk 20, 7-12",
                                text: "7 Keď sme sa v prvý deň týždňa zišli na lámanie chleba, Pavol s nimi hovoril, lebo na druhý deň chcel odcestovať, a pretiahol reč až do polnoci. 8 V hornej sieni, kde sme boli zhromaždení, bolo mnoho lámp. 9 Istý mladík, menom Eutychus, sedel v okne, a pretože Pavol dlho hovoril, tvrdo zaspal. Premožený spánkom spadol z tretieho poschodia dolu. Zdvihli ho mŕtveho. 10 Pavol zišiel dole, ľahol si naň, objal ho a povedal: „Neplašte sa, lebo jeho duša je v ňom.“ 11 Potom sa vrátil hore, lámal chlieb a jedol, dlho, až do svitania, ešte hovoril a potom odišiel. 12 Chlapca priviedli živého a nemálo ich to potešilo.",
                            },
                        ],
                    },
                    {
                        day: 7,
                        title: "Nedeľa",
                        text: "Sme vyzývaní k životu v spoločenstve, aby sme sa nesnažili žiť svoj život sami, ale aby sme k sebe pristupovali s láskou. V Božom pláne spásy je, aby sme sa podieľali na tvorbe jednoty v Duchu Svätom.",
                        scriptures: [
                            {
                                reference: "Ef 4, 1-6",
                                text: "4 Jedno je telo a jeden Duch, ako ste aj povolaní v jednej nádeji svojho povolania. 5 Jeden je Pán, jedna viera, jeden krst. 6 Jeden je Boh a Otec všetkých, ktorý je nad všetkými, preniká všetkých a je vo všetkých.",
                            },
                        ],
                    },
                ],
            },
            {
                week: "2. týždeň",
                daysContent: [
                    {
                        day: "intro",
                        title: "Úvod do týždňa",
                        text: "Tento týždeň sa budeme snažiť rozpoznať, ako môžeme prispieť k budovaniu a rastu spoločenstva. Boh sa k nám prihovára, preto majme srdcia otvorené.\n\n Chce, aby sme rástli a tvorili vzťahy. On sám ich budoval v spoločenstve svojich učeníkov, ktorí ho nasledovali. Žiadne spoločenstvo nie je dokonalé, ale môže sa stávať lepším aj tvojou snahou.",
                        scriptures: [],
                    },

                    {
                        day: 1,
                        title: "Pondelok",
                        text: "Ježiš si povoláva učeníkov a tvorí spoločenstvo, ktoré neostáva nečinné. Učeníci ho sprevádzajú na Jeho ceste a učia sa tvoriť jednotu v jej rozdielnosti. Ježiš nenasleduje ľudské štandardy. Na jednej strane povoláva Šimona Petra, radikálneho odporcu Rimanov, na druhej strane mýtnika Matúša, ich prisluhovača.\n\n Dajme sa aj my pri budovaní spoločenstva viesť Božím Duchom a nenechajme, aby nás ovplyvňovali ľudské ohľady..",
                        scriptures: [
                            {
                                reference: "Mt 4, 18-22",
                                text: "18 Keď raz kráčal popri Galilejskom mori, videl dvoch bratov, Šimona, ktorý sa volá Peter, a jeho brata Ondreja, ako spúšťajú sieť do mora; boli totiž rybármi. 19 I povedal im: „Poďte za mnou a urobím z vás rybárov ľudí.“ 20 Oni hneď zanechali siete a išli za ním. 21 Ako šiel odtiaľ ďalej, videl iných dvoch bratov, Jakuba Zebedejovho a jeho brata Jána, ako na lodi so svojím otcom Zebedejom opravujú siete; aj ich povolal. 22 Oni hneď zanechali loď i svojho otca a išli za ním.",
                            },
                            {
                                reference: "Mt 9, 9",
                                text: "9 Keď odtiaľ Ježiš odišiel, videl na mýtnici sedieť človeka menom Matúša a povedal mu: „Poď za mnou!“ On vstal a išiel za ním.",
                            },
                        ],
                    },
                    {
                        day: 2,
                        title: "Utorok",
                        text: "Každý má v spoločenstve svoju úlohu. Tá tvoja, nech je akákoľvek, je dôležitá! Vytváraš spolu s ostatnými jednotu tela. Ako by asi vyzeralo telo, keby mu chýbala ruka alebo noha!? Tak by vyzeralo spoločenstvo bez teba.",
                        scriptures: [
                            {
                                reference: "1 Kor 12, 12-27",
                                text: "12 Lebo ako je jedno telo a má mnoho údov, ale všetky údy tela sú jedno telo, hoci je ich mnoho, tak aj Kristus. 13 Veď my všetci, či Židia alebo Gréci, či otroci alebo slobodní, boli sme v jednom Duchu pokrstení v jedno telo. A všetci sme boli napojení jedným Duchom. 14 Telo nie je jeden úd, ale mnoho údov. 15 A keby noha povedala: „Nie som ruka, nepatrím k telu,“ tým ešte neprestáva patriť k telu. 16 A keby povedalo ucho: „Nie som oko, nepatrím k telu,“ tým ešte neprestáva patriť k telu. 17 Keby bolo celé telo okom, kde by bol sluch? A keby bolo celé sluchom, kde by bol čuch? 18 Ale Boh rozložil údy, každý jeden z nich, v tele, ako chcel. 19 Keby boli všetky jedným údom, kde by bolo telo? 20 No takto je mnoho údov, ale iba jedno telo. 21 A oko nemôže povedať ruke: „Nepotrebujem ťa!“ ani hlava nohám: „Nepotrebujem vás!“ 22 Ba čo viac, údy tela, ktoré sa zdajú slabšie, sú nevyhnutne potrebné. 23 A údy tela, ktoré pokladáme za menej ušľachtilé, zaodievame s väčšou úctou a naše nečestné údy majú tým väčšiu česť, 24 kým naše čestné nič také nepotrebujú. Boh telo vyvážil tak, že slabšiemu údu dal väčšiu česť, 25 aby nebola v tele roztržka, ale aby sa údy rovnako starali jeden o druhý. 26 Ak teda trpí jeden úd, trpia spolu s ním všetky údy, a ak vychvaľujú jeden úd, radujú sa s ním všetky údy. 27 Vy ste Kristovo telo a jednotlivo ste údy.",
                            },
                        ],
                    },
                    {
                        day: 3,
                        title: "Streda",
                        text: "„Máme rozličné dary podľa milosti, ktorú sme dostali.“ Vďaka našej jedinečnosti sa dokážeme navzájom obohacovať a spoločne rásť. Skús si dnes uvedomiť, čím môžeš byť darom pre druhých.",
                        scriptures: [
                            {
                                reference: "Rim 12, 3-8",
                                text: "3 Mocou milosti, ktorú som dostal, hovorím každému z vás, aby si nik nemyslel o sebe viac, ako myslieť treba, ale zmýšľajte triezvo, každý podľa stupňa viery, aký mu udelil Boh. 4 Lebo ako máme v jednom tele mnoho údov, ale všetky údy nekonajú tú istú činnosť, 5 tak aj my mnohí sme jedno telo v Kristovi a jednotlivo sme si navzájom údmi. 6 Máme rozličné dary podľa milosti, ktorú sme dostali: či už dar prorokovať v súlade s vierou, 7 alebo dar slúžiť v službe, alebo učiť pri vyučovaní, 8 či povzbudzovať pri povzbudzovaní. Kto teda dáva, nech dáva nezištne, kto je predstavený, nech je starostlivý, kto preukazuje milosrdenstvo, nech to robí radostne.",
                            },
                        ],
                    },
                    {
                        day: 4,
                        title: "Štvrtok",
                        text: "V Písme sa píše, že ako chceme, aby ľudia robili nám, máme tak robiť i my im. Podstatou dávania je láska. Pros dnes Ducha Svätého, aby naplnil tvoje srdce láskou a pomáhal ti deliť sa s telesnými, ale aj duchovnými dobrami.",
                        scriptures: [
                            {
                                reference: "Rim 1, 11-12",
                                text: "11 Lebo túžim vidieť vás a dať vám nejaký duchovný dar na vašu posilu, 12 to jest, aby sme sa navzájom potešili spoločnou vierou, vašou i mojou.",
                            },
                            {
                                reference: "Jak 2, 15-17",
                                text: "15 Ak je brat alebo sestra bez šiat a chýba im každodenná obživa 16 a niekto z vás by im povedal: „Choďte v pokoji! Zohrejte sa a najedzte sa!“, ale nedali by ste im, čo potrebujú pre telo, čo to osoží?! 17 Tak aj viera: ak nemá skutky, je sama v sebe mŕtva.",
                            },
                        ],
                    },
                    {
                        day: 5,
                        title: "Piatok",
                        text: "Aj v čase vlastnej púšte sme povolaní vychádzať zo seba a potešovať tých, ktorí sú v súžení. Skús si dnes nájsť čas a vypočuť si niekoho, kto sa trápi. Možno ho práve tvoja pozornosť povzbudí a prinesie ti večnú odmenu v nebi.",
                        scriptures: [
                            {
                                reference: "2 Kor 1, 3-5",
                                text: "3 Nech je zvelebený Boh a Otec nášho Pána Ježiša Krista, Otec milosrdenstva a Boh všetkej útechy! 4 On nás potešuje v každom našom súžení, aby sme mohli aj my potešovať tých, čo sú v akomkoľvek súžení, tou útechou, ktorou Boh potešuje nás. 5 Lebo ako sa v nás rozmnožujú Kristove utrpenia, tak sa skrze Krista rozhojňuje aj naša útecha.",
                            },
                        ],
                    },
                    {
                        day: 6,
                        title: "Sobota",
                        text: "Byť niekomu podriadený neznie práve najlákavejšie. A ešte k tomu nielen Bohu, ale aj ľuďom. Vždy, keď je to pre teba problém, spomeň si na Ježiša a Jeho podriadenosť Otcovi. „Ja som ťa oslávil na zemi: dokončil som dielo, ktoré si mi dal vykonať. A teraz ty, Otče, osláv mňa pri sebe slávou, ktorú som mal u teba skôr, ako bol svet.“",
                        scriptures: [
                            {
                                reference: "Flp 2, 3b",
                                text: "3 Nerobte nič z nevraživosti ani pre márnu slávu, ale v pokore pokladajte jeden druhého za vyššieho.",
                            },
                            {
                                reference: "Sk 9, 6",
                                text: "6 Ale vstaň, choď do mesta a povedia ti, čo máš robiť.“",
                            },
                        ],
                    },
                    {
                        day: 7,
                        title: "Nedeľa",
                        text: "Ježiš, vedomý si toho, že prichádza od Otca a k Otcovi sa navracia, umýva učeníkom nohy. Dáva im príklad lásky a učí ich skláňať sa a slúžiť si navzájom. Naša služba by taktiež mala vychádzať z vedomia, že sme milovaní Otcom a túžime sa mu pripodobniť aj v službe druhým.",
                        scriptures: [
                            {
                                reference: "Jn 13, 3-5",
                                text: "3 Ježiš vo vedomí, že mu dal Otec do rúk všetko a že od Boha vyšiel a k Bohu odchádza, 4 vstal od stola, zobliekol si odev, vzal plátennú zásteru a prepásal sa. 5 Potom nalial vody do umývadla a začal umývať učeníkom nohy a utierať zásterou, ktorou bol prepásaný.",
                            },
                            {
                                reference: "Jn 13, 12-15",
                                text: "12 Keď im umyl nohy a obliekol si odev, znova si sadol k stolu a povedal im: „Chápete, čo som vám urobil? 13 Vy ma oslovujete: »Učiteľ« a: »Pane« a dobre hovoríte, lebo to som. 14 Keď som teda ja, Pán a Učiteľ, umyl nohy vám, aj vy si máte jeden druhému nohy umývať. 15 Dal som vám príklad, aby ste aj vy robili, ako som ja urobil vám.",
                            },
                        ],
                    },
                ],
            },
            {
                week: "3. týždeň",
                daysContent: [
                    {
                        day: "intro",
                        title: "Úvod do týždňa",
                        text: "Keď Ježiš zakladá Cirkev, hovorí: „Ani brány pekelné ju nepremôžu.“ Môže sa nám zdať, že Cirkev je veľmi nedokonalá a je v nej mnoho hriechu. No predsa znamená veľa v Božích očiach. Prorok Izaiáš píše: „Ako si vezme mladík pannu, tak si ťa vezme staviteľ tvoj, a ako sa teší ženích neveste, tešiť sa bude Boh tvoj.“ (Iz 62,5) Sám Boh sa teší zo svojej nevesty Cirkvi. Aj Mária nech nás sprevádza tento týždeň svojím postojom, ktorým poukazuje na ženícha - Ježiša Krista.",
                        scriptures: [],
                    },
                    {
                        day: 1,
                        title: "Pondelok",
                        text: "KKC 787 Ježiš si už od začiatku vzal učeníkov za spoločníkov života; zjavil im tajomstvo Kráľovstva; dal im účasť na svojom poslaní, na svojej radosti i na svojom utrpení. Ježiš hovorí o ešte dôvernejšom spoločenstve medzi sebou a tými, čo ho budú nasledovať: „Ostaňte vo mne a ja ostanem vo vás… Ja som vinič, vy ste ratolesti“ (Jn 15,4-5). A oznamuje tajomné a skutočné spoločenstvo medzi svojím telom a naším telom: „Kto je moje telo a pije moju krv, ostáva vo mne a ja v ňom“ (Jn 6,56). Zúčastni sa dnes na sv. omši a pokús sa prežiť hĺbku Kristovho odkazu.",
                        scriptures: [
                            {
                                reference: "Ef 2, 19",
                                text: "19 Teda už nie ste cudzinci ani prišelci, ale ste spoluobčania svätých a patríte do Božej rodiny.",
                            },
                            {
                                reference: "1 Pt 2, 9",
                                text: "9 Ale vy ste vyvolený rod, kráľovské kňazstvo, svätý národ, ľud určený na vlastníctvo, aby ste zvestovali slávne skutky toho, ktorý vás z tmy povolal do svojho obdivuhodného svetla.",
                            },
                        ],
                    },
                    {
                        day: 2,
                        title: "Utorok",
                        text: "Cirkev je ovčincom, ktorého pastierom je sám Ježiš Kristus. On vedie všetky svoje ovečky k Otcovi. Podaj Mu svoju ruku s dôverou a nechaj sa Ním viesť.",
                        scriptures: [
                            {
                                reference: "Jn 10, 1-10",
                                text: "1 Veru, veru, hovorím vám: Kto nevchádza do ovčinca bránou, ale prelieza inokade, je zlodej a zbojník. 2 Kto vchádza bránou, je pastier oviec. 3 Tomu vrátnik otvára a ovce počúvajú jeho hlas. On volá svoje ovce po mene a vyvádza ich. 4 Keď ich všetky vyženie, kráča pred nimi a ovce idú za ním, lebo poznajú jeho hlas. 5 Za cudzím nepôjdu, ba ujdú od neho, lebo cudzí hlas nepoznajú.“ 6 Ježiš im to povedal takto obrazne, ale oni nepochopili, čo im to chcel povedať. 7 Preto im Ježiš znova vravel: „Veru, veru, hovorím vám: Ja som brána k ovciam. 8 Všetci, čo prišli predo mnou, sú zlodeji a zbojníci, ale ovce ich nepočúvali. 9 Ja som brána. Kto vojde cezo mňa, bude spasený; bude vchádzať i vychádzať a nájde pastvu. 10 Zlodej prichádza, len aby kradol, zabíjal a ničil. Ja som prišiel, aby mali život a aby ho mali hojnejšie.",
                            },
                        ],
                    },
                    {
                        day: 3,
                        title: "Streda",
                        text: "Každý z nás je živým kameňom a spoločne budujeme duchovný dom. Tento dom predstavuje zjednotený Boží ľud, v ktorom prebýva Duch Svätý. Každý kameň stojí na základe, ktorým je Ježiš Kristus. Je tvojím základom On?",
                        scriptures: [
                            {
                                reference: "1 Kor 3, 16",
                                text: "16 Neviete, že ste Boží chrám a že vo vás prebýva Boží Duch?",
                            },
                            {
                                reference: "Ef 2, 20-22",
                                text: "20 Ste postavení na základe apoštolov a prorokov; hlavným uholným kameňom je sám Kristus Ježiš. 21 V ňom celá stavba pevne pospájaná rastie v svätý chrám v Pánovi, 22 v ňom ste aj vy vbudovaní do Božieho príbytku v Duchu.",
                            },
                        ],
                    },
                    {
                        day: 4,
                        title: "Štvrtok",
                        text: "Jednota medzi Kristom a Jeho Cirkvou je dokonalá. Kristus dal svoj život za svoju nevestu Cirkev. Jeho láska nepozná hraníc. Nechaj sa dnes premieňať Ježišovou sebadarujúcou láskou, ktorá očisťuje a uzdravuje.",
                        scriptures: [
                            {
                                reference: "Ef 5, 22-32",
                                text: "22 Ženy svojim mužom ako Pánovi, 23 lebo muž je hlavou ženy, ako je aj Kristus hlavou Cirkvi, on, Spasiteľ tela. 24 Ale ako je Cirkev podriadená Kristovi, tak aj ženy mužom vo všetkom. 25 Muži, milujte manželky, ako aj Kristus miluje Cirkev a seba samého vydal za ňu, 26 aby ju posvätil očistným kúpeľom vody a slovom, 27 aby si sám pripravil Cirkev slávnu, na ktorej niet škvrny ani vrásky, ani ničoho podobného, ale aby bola svätá a nepoškvrnená. 28 Tak sú aj muži povinní milovať svoje manželky ako vlastné telá. Kto miluje svoju manželku, miluje seba samého. 29 Veď nik nikdy nemal v nenávisti svoje telo, ale živí si ho a opatruje, ako aj Kristus Cirkev, 30 lebo sme údmi jeho tela. 31 Preto muž zanechá otca i matku a pripúta sa k svojej manželke a budú dvaja v jednom tele. 32 Toto tajomstvo je veľké; ja hovorím o Kristovi a Cirkvi.",
                            },
                        ],
                    },
                    {
                        day: 5,
                        title: "Piatok",
                        text: "Mária prijímala Božie slovo a premýšľala o ňom vo svojom srdci. Spoznávala Jeho vôľu vo svojom živote a ochotne ju plnila. Pýtaj sa dnes Ducha Svätého, kam ťa túži zaviesť a aká je Jeho vôľa pre teba v tento deň.",
                        scriptures: [
                            {
                                reference: "Lk 1, 38",
                                text: "38 Mária povedala: „Hľa, služobnica Pána, nech sa mi stane podľa tvojho slova.“ Anjel potom od nej odišiel.",
                            },
                        ],
                    },
                    {
                        day: 6,
                        title: "Sobota",
                        text: "V žalme 119, 105 sa píše: „Tvoje slovo je svetlo pre moje nohy.“ Pre Máriu sa Božie slovo stalo ozajstným Svetlom, ktoré osvetľovalo jej život a sprevádzalo ju v jej krokoch. Boh túži k tebe prehovoriť skrze svoje Slovo. Načúvaj mu.",
                        scriptures: [
                            {
                                reference: "Lk 2, 51",
                                text: "51 Potom sa s nimi vrátil do Nazareta a bol im poslušný. A jeho matka zachovávala všetky slová vo svojom srdci.",
                            },
                        ],
                    },
                    {
                        day: 7,
                        title: "Nedeľa",
                        text: "Mária vo všetkom, čo robila, ukazovala na Ježiša. I tu hovorí: „Urobte všetko, čo vám povie!“ Jej príklad nás učí v každom čase sa obracať na Krista a dôverovať mu. Mária je našou milovanou matkou, ktorá nás celou svojou bytosťou túži priviesť k svojmu Synovi.",
                        scriptures: [
                            {
                                reference: "Jn 2,5",
                                text: "5 Jeho matka povedala obsluhujúcim: „Urobte všetko, čo vám povie!“",
                            },
                        ],
                    },
                ],
            },
        ],
    },
};

export default Texts;
