import React from "react";
import styled from "styled-components";
import he from "he";
import BackButton from "./BackButton";

// Vytvor styled component pre kontajner
const Container = styled.div`
    padding: 50px 10px 10px 10px;
`;

const htmlContent = `<div dir="ltr">Ahojte priatelia<img data-emoji="👋" class="an1" alt="👋" aria-label="👋" draggable="false" src="https://fonts.gstatic.com/s/e/notoemoji/15.1/1f44b/32.png" loading="lazy">,<div><br></div><div>verím, že sa máte dobre a užívate si víkend. Ak by ste náhodou nevedeli, čo s víkendom, tak mám tu pre vás jeden návrh, teda pripomienku.&nbsp;<img data-emoji="😅" class="an1" alt="😅" aria-label="😅" draggable="false" src="https://fonts.gstatic.com/s/e/notoemoji/15.1/1f605/32.png" loading="lazy"></div><div><b>15.11.</b> je totiž <b>deadline do odovzdania 1. časti DAŠ projektu</b>, takže máte ešte cca. 7 dní.</div><div><br></div><div>My animátori sme tu pre vás keby ste potrebovali s čímkoľvek pomôcť, poradiť, rozlíšiť. Neváhajte nám napísať.&nbsp;<img data-emoji="😉" class="an1" alt="😉" aria-label="😉" draggable="false" src="https://fonts.gstatic.com/s/e/notoemoji/15.1/1f609/32.png" loading="lazy">&nbsp;</div><div>Myslíme na vás v modlitbách.&nbsp;<img data-emoji="🙌" class="an1" alt="🙌" aria-label="🙌" draggable="false" src="https://fonts.gstatic.com/s/e/notoemoji/15.1/1f64c/32.png" loading="lazy"></div><div><br></div><div><p>Za tím DAŠ 2023-2025</p><p>Váš animátor Patrik</p></div></div>`;

const LastMail = () => {
    const decodedHtmlContent = he.decode(htmlContent);

    return (
        <Container>
            <BackButton to="/" />
            <div dangerouslySetInnerHTML={{ __html: decodedHtmlContent }} />
        </Container>
    );
};

export default LastMail;
