// src/App.js

import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DailyText from "./DailyText";

// Define the current version of the "Stay in Touch" content
const STAY_IN_TOUCH_VERSION = 3; // Increment this when new content is added

// Styled components
const Title = styled.h1`
    font-size: 1.6em;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    margin-top: 5px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    padding: 0 20px;
`;

const Logo = styled.img`
    width: 90px;
    height: auto;
`;

const Button = styled(Link)`
    position: relative;
    width: 200px;
    padding: 15px;
    margin: 10px;
    font-size: 1.2em;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 10px;
    background-color: ${(props) =>
        props.light ? "#34A853" : props.purple ? "#A472D8" : "#007bff"};
    color: white;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${(props) =>
            props.light ? "#2e8b47" : props.purple ? "#8A3EBF" : "#0056b3"};
    }

    // Small "new" badge in the top-right corner
    &::after {
        content: "${(props) => (props.new ? "NEW" : "")}";
        display: ${(props) => (props.new ? "block" : "none")};
        position: absolute;
        top: -8px;
        right: -8px;
        background-color: #ff5722; /* Orange color */
        color: white;
        font-size: 0.7em;
        font-weight: bold;
        padding: 2px 5px;
        transform: rotate(10deg);
    }
`;

const WelcomeOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    text-align: center;
    z-index: 1000;
`;
const Home = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [isNew, setIsNew] = useState(false);

    useEffect(() => {
        // Check for first visit overlay
        const firstVisit = localStorage.getItem("firstVisit");
        if (!firstVisit) {
            setShowOverlay(true);
            localStorage.setItem("firstVisit", "no");
        }

        // Check if the current version is new for the "Stay in Touch" section
        const viewedVersion = parseInt(
            localStorage.getItem("stayInTouchVersion") || "0",
            10
        );
        if (viewedVersion < STAY_IN_TOUCH_VERSION) {
            setIsNew(true); // Show the "NEW" badge
        }
    }, []);

    const closeOverlay = () => setShowOverlay(false);

    const handleStayTouchClick = () => {
        // Mark the current version as viewed in localStorage
        localStorage.setItem(
            "stayInTouchVersion",
            STAY_IN_TOUCH_VERSION.toString()
        );
        setIsNew(false); // Hide the "NEW" badge
    };
    return (
        <>
            {showOverlay && (
                <WelcomeOverlay onClick={closeOverlay}>
                    <Confetti
                        width={window.innerWidth}
                        height={window.innerHeight}
                    />
                    Vitaj v DAŠ príručke účastníka! 🥳
                </WelcomeOverlay>
            )}

            <Container>
                <Logo
                    src={`${process.env.PUBLIC_URL}/premeny-logo.png`}
                    alt="Logo"
                />
                <Title>DAŠ príručka účastníka</Title>
                <DailyText />
                <Button to="/prvy-rocnik">Prvý ročník</Button>
                <Button to="/druhy-rocnik">Druhý ročník</Button>
                <Button to="/last-mail" light>
                    Posledný mail
                </Button>
                <Button
                    to="/stay-touch"
                    purple
                    new={isNew}
                    onClick={handleStayTouchClick}
                >
                    Stay in touch 👉👈
                </Button>
            </Container>
        </>
    );
};

export default Home;
