// src/App.js

import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Components/Home";
import LastMail from "./Components/LastMail";
import StayTouch from "./Components/StayTouch";
import Texts from "./Texts";
import { getOrCreateUserId } from "./Utils/UUIDGenerator";
import FirstYear from "./YearBook/FirstYear/FirstYear";
import SecondYear from "./YearBook/SecondYear/SecondYear";
import CommunionAndLiturgy from "./YearBook/SecondYear/Topics/CommunionAndLiturgy";

function App() {
    const location = useLocation();

    const trackVisit = () => {
        const userId = getOrCreateUserId();
        const url = window.location.href;

        fetch("https://backend.das-vazec.sk/track-visit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-api-key":
                    "ksadlf84u8rwuturohgas8gouiawr8p4oug84ahopor8guasp8ahglsdhgawr8iugpas8guashl",
            },
            body: JSON.stringify({ userId, url }),
        });

        // fetch("http://localhost:5000/track-visit", {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "x-api-key":
        //             "ksadlf84u8rwuturohgas8gouiawr8p4oug84ahopor8guasp8ahglsdhgawr8iugpas8guashl",
        //     },
        //     body: JSON.stringify({ userId, url }),
        // });
    };

    useEffect(() => {
        const handleClick = () => {
            trackVisit();
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/prvy-rocnik" element={<FirstYear />} />
            <Route path="/druhy-rocnik" element={<SecondYear />} />
            <Route path="/last-mail" element={<LastMail />} />
            <Route path="/stay-touch" element={<StayTouch />} />
            <Route
                path="/druhy-rocnik/spolocenstvo-cirkvi-a-liturgia"
                element={
                    <CommunionAndLiturgy
                        texts={Texts["spolocenstvo-cirkvi-a-liturgia"]}
                    />
                }
            />
        </Routes>
    );
}

export default App;
