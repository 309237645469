import React from "react";
import styled from "styled-components";
import BackButton from "./BackButton";

// Vytvor styled component pre kontajner
const Container = styled.div`
    padding: 50px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Image = styled.img`
    width: 100%;
    max-width: 800px; /* Optional max-width to control size on large screens */
    margin-bottom: 20px;
`;

const StayTouch = () => {
    return (
        <Container>
            <BackButton to="/" />
            <Image src={`${process.env.PUBLIC_URL}/dsmo.jpg`} alt="DSMO" />
            <Image src={`${process.env.PUBLIC_URL}/dsmm.jpg`} alt="DSMM" />
            <Image
                src={`${process.env.PUBLIC_URL}/stretnutiepapa.jpg`}
                alt="Stretnutie mladých v Ríme"
            />
        </Container>
    );
};

export default StayTouch;
